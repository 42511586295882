import { fetchGet, fetchPost, jsonToQueryParam } from "./CommonServiceUtils";
import { LOYALTY_SERVICE_URL } from "./../Constants";

const getLoyaltyProfile = () =>
  fetchGet(`${LOYALTY_SERVICE_URL}accounts/profile`);

const getRewards = (queryObj) =>
  fetchGet(`${LOYALTY_SERVICE_URL}rewards?${jsonToQueryParam(queryObj)}`);

const getOrganizationsConfig = () =>
  fetchGet(`${LOYALTY_SERVICE_URL}organizations`);

const getRegionsConfig = () => fetchGet(`${LOYALTY_SERVICE_URL}regions`);

const getTiers = (queryObj) =>
  fetchGet(`${LOYALTY_SERVICE_URL}tiers?${jsonToQueryParam(queryObj)}`);

const getPointRules = (queryObj) =>
  fetchGet(`${LOYALTY_SERVICE_URL}pointrules?${jsonToQueryParam(queryObj)}`);

const getTransactions = (queryObj) =>
  fetchGet(`${LOYALTY_SERVICE_URL}transactions?${jsonToQueryParam(queryObj)}`);

const redeemReward = (payload) =>
  fetchPost(`${LOYALTY_SERVICE_URL}rewardRedeem`, payload);

const transferPoints = (payload) =>
  fetchPost(`${LOYALTY_SERVICE_URL}points/transfer`, payload);

const getCharities = (queryObj) =>
  fetchGet(`${LOYALTY_SERVICE_URL}charities?${jsonToQueryParam(queryObj)}`);

const getMemberByMobileNumber = (queryObj) =>
  fetchGet(`${LOYALTY_SERVICE_URL}members/filter?${jsonToQueryParam(queryObj)}`);

const getRewardLogs = (queryObj) =>
  fetchGet(
    `${LOYALTY_SERVICE_URL}redemptionlogs?${jsonToQueryParam(queryObj)}`
  );

const getLocations = (queryObj) =>
  fetchGet(`${LOYALTY_SERVICE_URL}locations?${jsonToQueryParam(queryObj)}`);

const getPortalPublicData = () =>
  fetchGet(`${LOYALTY_SERVICE_URL}publicdata`);

/* // TODO: Announcements will be implemented in the future
    const getAnnouncements = () => {
        return fetchGet(LOYALTY_SERVICE_URL + "announcements", true, true);
    }; 
*/

export {
  getOrganizationsConfig,
  getRegionsConfig,
  getPointRules,
  getRewards,
  getLoyaltyProfile,
  redeemReward,
  getTransactions,
  getRewardLogs,
  getTiers,
  getLocations,
  transferPoints,
  getCharities,
  getMemberByMobileNumber,
  getPortalPublicData,
};
